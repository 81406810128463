export const SelectronMe = (props) => {
  return (
    <div className="pageContent">
      <h1>What is new?</h1>
      <ul>
        <li>Transverse is ready for the test run!</li>
        <li>
          A sneak peek for Project Atom is ready for the art and science lovers!
        </li>
      </ul>
      <h1>What's coming</h1>
      <ul>
        <li>
          I will be in Turkey for the summer and will be posting upcoming events
          in here
        </li>
      </ul>
    </div>
  );
};
