export const Transverse = (props) => {
  return (
    <div className="pageContent">
      <h1>Transverse</h1>
      <p>
        Transverse is a digital experience to gather artists and art lovers in a
        space without any boundaries. The project aims to help artist to show
        their art and reach a larger community. The project also has offline
        elements to cultivate local communities to combine alike energies
        together. The digital experience contains game-like virtual gallery
        experience and classical type collection showroom.
      </p>
      <h2>Technologies Used</h2>
      <ul>
        <li>Unity</li>
        <li>ReactJS</li>
        <li>WebGL</li>
        <li>Web3</li>
        <li>Solidity</li>
      </ul>
      <div className="buttonContainer">
        <a
          href="https://master.d32ci9aw4zgflw.amplifyapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Visit Project</button>
        </a>
      </div>
    </div>
  );
};
