export const QuantumMachine = (props) => {
  return (
    <div className="pageContent">
      <h1>Quantum Machine</h1>
      <p>
        Quantum Machine is a visual frame work and automatization tool for
        Quantum Espresso. The framework allows users to generate workflow for
        material research. The package can be connected to high-performance
        backend with no effort.
      </p>
      <h2>Technologies Used</h2>
      <ul>
        <li>Quantum Espresso</li>
        <li>ReactJS</li>
        <li>Flask</li>
        <li>Bash Script</li>
        <li>Message Passing Interface (MPI)</li>
      </ul>
      <div className="buttonContainer">
        <a
          href="https://main.d1bse3yof20d4u.amplifyapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Visit Project</button>
        </a>
      </div>
    </div>
  );
};
