export const ProjectAtom = (props) => {
  return (
    <div className="pageContent">
      <h1>Project Atom</h1>
      <p>
        Project Atom is an NFT collected generated by using scientific
        calculations. The aim of the project is show that it is possible to do
        science without any establishment. This project is generated by using
        Density Functional Theory calculation for materials. By joining this
        community you became a part of free science movement.
      </p>
      <h2>Technologies Used</h2>
      <ul>
        <li>Quantum Espresso</li>
        <li>Wannier90</li>
        <li>Message Passing Interface (MPI)</li>
        <li>Web3</li>
        <li>Solidity</li>
      </ul>
      <div className="buttonContainer">
        <a
          href="https://main.d1bse3yof20d4u.amplifyapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Visit Project</button>
        </a>
      </div>
    </div>
  );
};
